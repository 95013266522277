import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Wordpress",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/wordpress.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`WordPress`}</h1>
    <p>{`Easy-to-install Live chat plugin for WordPress`}</p>
    <hr></hr>
    <p>{`WordPress is the most popular CMS, running millions of websites. Looking for an easy-to-install live chat plugin for your website on Wordpress ? Using this integration, add the Crisp Live chat for free in a single click on WordPress!`}</p>
    <p>{`Benefits of using our Live Chat plugin for WordPress:`}</p>
    <ul>
      <li parentName="ul">{`easy Live chat plugin setup in a single click without technical skills`}</li>
      <li parentName="ul">{`Manage all your conversations from the Crisp Inbox`}</li>
      <li parentName="ul">{`Automatically push user data when someone is already logged-in`}</li>
      <li parentName="ul">{`Automatically adapts to your website language (50+ locales are supported)`}</li>
      <li parentName="ul">{`Available from the basic plan (For free!)`}</li>
      <li parentName="ul">{`Create conversational experience with your website visitors`}</li>
      <li parentName="ul">{`Create conversational experience with your website visitors`}</li>
      <li parentName="ul">{`Decrease bounce rate with automatic instant messages`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      